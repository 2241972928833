<template>
  <section class="card">
    <transition name="modal" mode="in-out">
      <modal v-if="modals.show"
             :closemodal="cancelar"
             @salvar="salvar"
             @reset="reset"
             :errors="this.$root.errors"
             :title="getModalTitle()"
             :size="'modal-full'">
        <template slot="body" >
          <pessoa-partial :params="{Cliente: itemSelecionado[0].Pessoa}"></pessoa-partial>
          <h4 class="text-primary">Sistema</h4>
          <div class="row form-group">
            <div class="col-lg-3">
              <div class="form-group">
                <label>Login</label>
                <input type="text" class="form-control form-control-sm" v-model="itemSelecionado[0].Login" readonly />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <label asp-for="StatusUsuario">Status</label>
                <select v-model="itemSelecionado[0].StatusUsuario"
                        asp-for="StatusUsuario"
                        class="form-control form-control-sm">
                  <template v-for="(i,$index) in statusUsuario">
                    <option :key="i" :value="$index">{{i}}</option>
                  </template>

                </select>
              </div>

            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <label for="notifica">Notif. Veic. Pronto</label>

                <div class="checkbox-custom checkbox-default">
                  <input type="checkbox" class="form-control form-control-sm" name="notifica" id="notifica" v-model="itemSelecionado[0].NotificaPronto" />
                  <label for="notifica"></label>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label class="">Perfis</label>
                <v-select multiple append-to-body v-model="itemSelecionado[0].Perfis" :options="perfis" label="Descricao" value="Id"></v-select>

              </div>

            </div>
          </div>
          <div class="row form-group">
            <div class="col-lg-2">
              <div class="form-group">
                <label>Código ERP</label>
                <input type="text" class="form-control form-control-sm" v-model="itemSelecionado[0].IdIntegracao" />

              </div>

            </div>
          </div>
          <div class="row form-group">
            <div class="col-lg-4">
              <div class="form-group">
                <label>Empresas do grupo</label>
                <table class="table table-condensed table-hover table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Acesso?</th>
                      <th>Empresa</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(i,index) in itemSelecionado[0].EmpresasSelecionadas">
                      <tr :key="i.Id">
                        <td>
                          <div class="form-group">
                            <div class="checkbox-custom checkbox-default">
                              <input type="checkbox"
                                     @change="empresaSelecionada = ''"
                                     class="form-control form-control-sm"
                                     :name="index+'-empresa'" v-model="i.isChecked" />
                              <label :for="index+'-empresa'"></label>
                            </div>

                          </div>
                        </td>
                        <td>{{i.NomeEmpresa}}</td>
                      </tr>

                    </template>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label>Tabela de limites de aprovação por empresa</label>
                <table class="table table-condensed table-hover table-bordered table-sm">
                  <thead>
                    <tr>
                      <th class="text-right">Selecione: </th>
                      <th>
                        <select class="form-control form-control-sm" v-model="empresaSelecionada">
                          <template v-for="(i) in itemSelecionado[0].EmpresasSelecionadas.filter(e=>e.isChecked)">
                            <option :key="i.EmpresaId"
                                    :value="i.EmpresaId">
                              {{i.NomeEmpresa}}
                            </option>
                          </template>
                        </select>
                      </th>
                    </tr>
                    <tr v-if="empresaSelecionada">
                      <th>Tipo Requisição</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody v-if="empresaSelecionada">
                    <template v-for="i in itemSelecionado[0].TipoRequisicaoUsuarioLimite.filter(e=>e.EmpresaId === empresaSelecionada)">
                      <tr :key="i.Tipo">
                        <td>{{i.Tipo}}</td>
                        <td>
                          <div class="form-group">
                            <money v-model="i.Valor"
                                   class="form-control form-control-sm"></money>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </template>
        <template  v-slot:footer="slotFooterProps">
          <button class="btn btn-sm btn-success modal-confirm" v-if="itemSelecionado[0].Id !== 0" @click="slotFooterProps.clickHandler('reset')">Resetar senha</button>
          <button class="btn btn-sm pull-right btn-primary modal-confirm"
                  @click="slotFooterProps.clickHandler('salvar')">
            Salvar
          </button>
        </template>
      </modal>
    </transition>

    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header" >
        <span class="selecionado" v-html="selecionado">
        </span>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
      </filtro-component>
      <data-table :result="result"
                  :params="params"
                  :handleRowClick="handleRowClick"
                  :checkboxClass="resolveCheckboxClass"
                  :itemSelecionado="itemSelecionado[0]"
                  :resolveStatusClass="resolveStatusClass"
                  :handleOrdenar="handleOrdenar"
                  :selected="selected"
                  :filtros="filtros"
                  :handleConsultar="handleConsultar">
      </data-table>
    </div>
  </section>
</template>

<script>
import api from '@/services/api.js';
import consultar from '@/services/consultar.js';
import { statusUsuario } from '@/services/enums.js';
import pessoaPartial from '@/components/PessoaPartial.vue';

//usuarios
export default {

    components: {
        ...consultar.components,
        'pessoa-partial': pessoaPartial,
    },
    data() {
        const that = this;
        return {
            ...consultar.data(),
            url: '/configuracoes/usuarios',
            clone: '',
            obj: {},
            actionButtons: [
                {
                    text: 'Editar',
                    className: 'editar padrao',
                    icon: 'fas fa-edit',
                    color: 'default',
                    visible: true,// inicial
                    visibleOnRowSelected: true,
                    title: 'Editar item selecionado',
                    callback: that.handleEditarButton
                },
                {
                    text: '',
                    className: 'novo padrao',
                    icon: 'fas fa-plus',
                    color: 'default',
                    visible: true,
                    title: 'Incluir novo usuario',
                    callback: that.handleNovoButton
                },
                {
                    text: '',
                    className: 'excel padrao',
                    icon: 'fas fa-file-excel',
                    iconColor: 'green',
                    color: 'default',
                    visible: true,
                    title: 'Exportar para excel',
                    callback: that.exportar
                },
            ],
            filtros:
            {
                data: [
                    {
                        id: 'nome',
                        title: 'Nome',
                        active: false,
                        placeholder: '',
                        //value: 'zueira'
                    },
                    {
                        id: 'email',
                        title: 'Email',
                        active: false,
                        placeholder: '',
                        //value: 'zueira'
                    },
                    {
                        id: 'documento',
                        title: 'Documento',
                        active: false,
                        placeholder: '',
                        //value: 'zueira'
                    },
                    {
                        id: 'StatusUsuario',
                        title: 'Status',
                        active: false,
                        value: [],
                        source: [
                            {
                                id: 'Inativo',
                                title: 'Inativo',
                                value: 0
                            },
                            {
                                id: 'Ativo',
                                title: 'Ativo',
                                value: 1
                            },
                            {
                                id: 'Bloqueado',
                                title: 'Bloqueado',
                                value: 2
                            }
                        ],
                        sourceType: 'radio'
                    }
                ],
                visao: [
                    {
                        id: 0,
                        title: 'Todos usuários',
                        filtro: []
                    },
                    {
                        id: 1,
                        title: 'Usuários Ativos',
                        filtro:
                            [
                                {
                                    id: 'StatusUsuario',
                                    value: 1,
                                },
                            ]
                    }
                ],
                visaoSelecionada: 0,
                visible: true
            },
            statusUsuario: statusUsuario,
            tipoRequisicao: [],
            empresas: [],
            perfis: [],
            empresaSelecionada: ''
        };
    },

    methods: {
      ...consultar.methods,
      handleNovoButton: function () {
          this.$root.errors = [];
          this.selected.pop();
          this.itemSelecionado.pop();
          this.itemSelecionado.push({
              Id: 0,
                  StatusUsuario: 1,
              Pessoa: {
                  Id: 0,
                  Estado: {
                      Descricao: ''
                  },
                  Endereco: [{
                      Cidade: {

                      },
                      CEP: '',
                      Bairro: '',
                      Logradouro: '',
                      Numero: '',
                  }],
                  Contato: [{
                      TipoContato: 0,
                      Tratamento: 0
                  }]
              },
              Perfis: [],
              EmpresasSelecionadas: this.empresas,
              TipoRequisicaoUsuarioLimite: this.tipoRequisicao
          });
          this.empresaSelecionada = '';
          this.modals.show = true;

      },
      handleEditarButton: function () {
          if (this.itemSelecionado.length) {
              this.$root.errors = [];

              

              if (!this.itemSelecionado[0].Pessoa.Endereco || !this.itemSelecionado[0].Pessoa.Endereco.length) {
                  this.itemSelecionado[0].Pessoa.Endereco = [{
                      Cidade: {

                      },
                      CEP: '',
                      Bairro: '',
                      Logradouro: '',
                      Numero: '',
                  }];
              }
              else if (this.itemSelecionado[0].Pessoa.Endereco[0].Cidade && this.itemSelecionado[0].Pessoa.Endereco[0].Cidade.Estado) {
                  
                  document.novaCidade = this.itemSelecionado[0].Pessoa.Endereco[0].Cidade.Descricao;
              }

              this.obj = this.itemSelecionado[0];
              this.clone = JSON.stringify(this.itemSelecionado[0]);
              this.empresaSelecionada = '';

              this.modals.show = true;
          }
      },
      reset: function () {
          let that = this;
          api.post('/configuracoes/resetarsenhausuario', { id: this.itemSelecionado[0].Id })
              .then(response => {
                  console.log(response.data);
                  if (response.data.success) {
                      that.modals.show = false;
                      that.itemSelecionado.pop();
                      that.selected.pop();
                  }
                  else {
                      that.$root.errors = response.data.errors;
                  }
              });
      },
      callback: function (request) {
          let { tipoRequisicao, empresas, perfis } = request.data.More;
          this.tipoRequisicao = tipoRequisicao;
          this.empresas = empresas;
          this.perfis = perfis;
      },
      salvar: function () {
            this.$root.errors = [];
            this.itemSelecionado[0].Login = this.itemSelecionado[0].Pessoa.Email;
            api
                .post(this.url, this.itemSelecionado[0])
                .then(response => {
                    console.log(response.data);
                    if (response.data.success) {

                        //busco id do item que retornou no array
                        let x = this.result.Records.filter(i => i.Id === response.data.obj.Id);
                        //se encontreim substituo o item
                        if (x.length) {
                            this.result.Records.splice(this.result.Records.indexOf(x[0]), 1, response.data.obj);
                        }
                        //se não encontrei, insiro ele na primeira posição
                        else {
                            this.result.Records.splice(0, 0, response.data.obj);
                        }
                        this.modals.show = false;
                        this.itemSelecionado.pop();
                        this.selected.pop();
                        this.changeCheckboxCallback();
                    }
                    else {
                        this.$root.errors = response.data.errors;
                    }
                });

        },
      resolveStatusClass(status) {
        switch (status) {
        case 'Ativo':
            return 'success';
        case 'Inativo':
            return 'muted';
        case 'Bloqueado':
            return 'danger';
        default: //encerrada
            return 'dark';
    }
      }
    },
    mounted: consultar.mounted,
};
</script>
